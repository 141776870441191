import * as React from "react"
import Layout from "../../components/Layout";
import ListOfGalleries from "../../components/ListOfGalleries";
import { useHomePageContent } from "../../hooks/useHomePageContent";

const GalleriesPage = () => {
    const galleries = useHomePageContent().galleries;
    
    return (
        <Layout pageTitle="Galleries">
            <ListOfGalleries galleries={galleries}/>
        </Layout>
    );
}

export default GalleriesPage;